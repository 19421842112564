body{
  background-color: #ffffff!important;
}

.aside-menu {
  overflow-y: auto;
}

.table-hover tbody td,
.table-hover tbody th {
  cursor: pointer;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: var(--light);
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

div.imagechoice {
  max-width: 220px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

img.choice {
  display: block;
  width: 100%;
  height: auto;
}

.survey-choice-img-overlay {
  background-color: #262626;
  opacity: 0.5;
  max-width: 220px;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  cursor: pointer;
}

.survey-choice-button {
  max-width: 220px;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.s-alert-box {
  z-index: 5000 !important;
}

.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.sidebar .nav-dropdown {
  border-left: 3px solid white;
  white-space: nowrap !important;
}

.sidebar .nav-dropdown.open {
  border-top: 1px solid var(--dark) !important;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-link .nav-icon {
    visibility: visible !important;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    left: 48px !important;
    overflow-x: hidden;
  }

  .sidebar-minimized .sidebar .nav-link {
    width: 248px !important;
  }

  .sidebar-minimized .sidebar .nav-link:hover {
    width: 248px !important;
  }

  .sidebar-minimized .sidebar .nav-dropdown.open {
    border-top: 0px;
  }
}

.map-marker {
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

.entity-search-marker {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 12px;
}

.material-palette-item {
  width: 18px;
  height: 18px;
  margin: 1px;
  cursor: pointer;
  border-radius: 9px;
}

div.color-picker-selector {
  width: calc(1em + 0.75rem + 2px);
  height: calc(1em + 0.75rem + 2px);
  cursor: pointer;
  border-radius: calc((1.5em + 0.75rem + 2px) / 2);
  display: inline-block;
}

.public-container {
  background-color: var(--white);
}

.app-logo-dashboard {
  width: 36px;
  height: 36;
}

.action-search-item-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.action-search-item-icon i {
  margin-top: 8px !important;
  margin-left: 5px !important;
}

.dropdown-item .subtitle {
  color: #73818f !important;
}

.dropdown-item .history {
  color: #73818f !important;
}

.dropdown-item.active .history {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dropdown-item:active .history {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dropdown-item.active .subtitle {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dropdown-item:active .subtitle {
  color: rgba(255, 255, 255, 0.6) !important;
}

.quantity-widget-icon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.conference-date {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.conference-date:hover {
  text-decoration: none;
}

.h7 {
  font-size: 0.7rem;
}

.conference-date .day {
  font-size: 0.7rem;
  margin-right: 1px;
}

.conference-date .month {
  font-size: 0.6rem;
}

.required-field {
  color: red;
}

.dropdown-menu {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
}

.shine {
  background: #ececec;
  background-image: linear-gradient(
    100deg,
    #ececec00 0%,
    #ecececff 50%,
    #ececec00 100%
  );
  background-repeat: no-repeat;
  background-size: 400px 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: left -500px top;
  }

  100% {
    background-position: right -500px top;
  }
}

.dashboard-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
}
.dashboard-grid-item {
  padding-left: 30px;
  background-clip: padding-box;
}

.pagination.borderless .page-item .page-link {
  border: 0;
}

.page-item.active .page-link {
  border-radius: 0.2rem;
}

.page-link:hover {
  border-radius: 0.2rem;
}

div.usermedia {
  background-color: var(--light);
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}

img.usermedia-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
}

img.usermedia-file {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  z-index: 0;
}

div.usermedia-tag {
  background-color: #262626cc;
  position: absolute;
  width: 120px;
  bottom: 0;
  z-index: 1;
  color: var(--white);
  font-size: 0.7rem;
}

a.usermedia-remove {
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  width: 18px;
  height: 18px;
  color: red !important;
  text-decoration: none !important;
  right: -8px;
  top: -8px;
  z-index: 1;
}

i.usermedia-video {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 0.75rem;
  margin: 0.5rem;
}

div.floating-footer-action-bar {
  background-color: white;
  position: fixed;
  width: inherit;
  bottom: 0;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 0.5rem;
  border: 1px solid #c8ced3;
  border-bottom: none;
  border-top-left-radius: 0.25rem;
  z-index: 1000;
}

div#static-footer-bar {
  padding-bottom: 0.5rem;
}

div.floating-count-down {
  z-index: 1000;
  bottom: 64px;
  opacity: 0.85;
}

div.tooltip-inner {
  min-width: 100px;
  max-width: 100%;
}

.react-avatar {
  object-fit: contain;
  background-color: #ffffff;
}

.img-container img,
.img-container iframe {
  max-width: 100%;
}

.unlink {
  pointer-events: none;
  cursor: pointer;
}

.unlink a {
  color: inherit !important;
}

.select2-container {
  width: 100% !important;
}

/* General Classes*/
.width-15
{
  width: 15px !important;
}
.width-20
{
  width: 20px !important;
} 
.width-25
{
  width: 25px !important;
}
.width-50
{
  width: 50px !important;
}

.width-75
{
  width: 75px !important;
}

.width-100
{
  width: 100px !important;
}

.display-inline-block
{
  display: inline-block;
}

.font-weight-bold-2
{
  font-weight: 600;
}
 
.shaded-panel
{
  -webkit-box-shadow: 0 15px 15px rgba(153, 153, 153, 0.3);
  box-shadow: 0 15px 15px rgba(153, 153, 153, 0.3);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
/* End */
/* Progrss card styles */
.progress-card {
  border-radius: 0.5em !important;
  border: #ffffff 1px solid;
  
}

.progress-card-value {
  width: 25%;
  float: left;
  text-align: center;
  padding-top: 1.5em;
}

.progress-card-value span {
  font-size: 1.7rem;
}

.progress-card-details {
  width: 75%;
  float: left;
}

.progress-card-details label {
  height: 60px;
  padding: 10px; 
  font-size: 1.3em;
  font-weight: 400;
  padding-right: 1.5rem;
}

.progress-card-details a {
  position: absolute;
  right: 10px;
  top: 5px;
}

.progress-card-details-sections div {
  width: 50%;
  float: left;
  height: 2em;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  padding-top: 0.3em;
  font-size:0.77rem;
}

.progress-card-details-sections div:first-child {
  width: 49%;
}

.progress-card-details-sections div:last-child {
  float: right;
}

/* --- End --- */

/* carousel */
.carousel-root .control-dots
{
  bottom:  -20px !important;
  margin-bottom:  20px !important; 
}


.carousel .control-dots .dot.selected{ 
  background: #d2d2d2;
} 

.carousel.carousel-slider .control-arrow {
  background: rgba(0,0,0,0.2) !important; 
}
/**/
 
 
/* attendee widtget */
.attendee-widget
{
  position: relative;
  border-right: 3px solid #d2d2d2;
  min-height: 100px;
}

.attendee-widget.no-border
{
  border-right:none;
}


.attendee-widget .attendee-widget-value
{
  position: absolute;
  right: 10px;
  top: 5px;
}

.attendee-widget .attendee-widget-value-text
{
  font-size: 2em; 
}

.attendee-widget .attendee-widget-value-icon
{
  font-size: 1.5em; 
  top: 10px;
}
/**/

.count-down-days, .count-down-hours, .count-down-minutes, .count-down-seconds
{
  display: inline-block;
  padding: 15px;
  width: 80px;
  border-radius: 5px;
  color: black;
}

.count-down-days {
  background: #ffc107; 
}

.count-down-hours {
  background: #20a8d8; 
}

.count-down-minutes {
  background: #4dbd74;
}

.count-down-seconds {
  background: #ffffff; 
}

.count-down-numbers {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: black;
  font-size: 2em;
}

.count-down-period span
{
  font-weight: 600;
  font-size: 1rem;
}

/** General**/
.line-clamp-1
{
  -webkit-line-clamp: 1 !important;
}

.line-clamp-2
{
  -webkit-line-clamp: 2 !important;
}

.line-clamp-3
{
  -webkit-line-clamp: 3 !important;
}

/********/
.ad-highlight-text
{
  font-size: 1.4rem;
  font-weight: 600;
}

@media (min-width: 1300px) {
  .welcome-panel
  {
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .welcome-search-panel {
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  } 
}

@media (max-width: 1299px) {
  .welcome-panel
  {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .welcome-search-panel {
    display: none;
  }
}

.widget-total-label
{
  font-size: 1em;
}

@media (max-width: 1299px) {
  .widget-total-label
  {
    font-size: 0.9em;
  }

  .widget-total-numbers-holder
  {
    padding-right: 0px !important;
    padding-left: 5px !important;
  }
}

/* reg-workshop-box */
.reg-workshop-box
{
  border: 1px #d2d2d2 solid;
  padding: 10px;
}

.reg-workshop-box .date {
  background: #f2f2f2;
  padding: 10px 10px 10px 10px;
  vertical-align: middle; 
}
.reg-workshop-box .title
{
  /* border-bottom: 1px #d2d2d2 dashed; */
  background: #f2f2f2;
  padding: 10px 10px 10px 10px;
  vertical-align: middle; 
}

.reg-workshop-box .details
{
  border: 1px #d2d2d2 dashed;
  padding: 10px;
  position: relative;
}



.reg-workshop-box .track
{
  border-top: 1px #d2d2d2 dotted;
  padding-top: 10px;
}

.reg-workshop-box .presenter
{
  border-top: 1px #d2d2d2 dotted;
  padding-top: 10px;
}

/* End: reg-workshop-box */


/* Ribbon */
/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 5;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
} 
/*****/

.text-transform-uppercase
{
  text-transform: uppercase;
}

.text-transform-uppercase-valid:valid
{
  text-transform: uppercase;
} 

.bordered-img
{
  border: #d2d2d2 1px solid;
}

.login-conference-image
{
  max-width: 100%;
  min-height: 110px;
}


/* template-canvas-grid */
.template-canvas-grid{
  width: 100%;
  height: 100%;
}

.template-canvas-grid-lined{
  background: url(data:image/gif;base64,R0lGODlhFAAUAIAAAMDAwP///yH5BAEAAAEALAAAAAAUABQAAAImhI+pwe3vAJxQ0hssnnq/7jVgmJGfGaGiyoyh68GbjNGXTeEcGxQAOw==);
}
/***********************/

.cursor-pointer{
  cursor: pointer
} 
/**************************/
.selected-booth
{
  border-color: #262626!important;
}
 
.booth-info-editor .card-body
{
  background-color: #FFFFFF;
}

/**** Shapes ****/
.booth-oval
{
  border-radius: 50%; 
}

 

.free-booth-container {
  width: 100%; /* Set an initial width for the container */
  height:  100%; /* Set an initial height for the container */
  overflow: hidden; /* Ensure the SVG scales within the container */
  transition: all 0.3s ease; /* Add a smooth transition effect */
}

.free-booth-container-selected
{
  border: 1px solid #ddd;
}

.custom-shape {
  width: 100%; /* Make the SVG fill the width of the container */
  height: 100%; /* Make the SVG fill the height of the container */
}

@media (min-width: 576px)
{
  .modal-free-shape {
    max-width: 333px!important;
  }
} 
 
.booth-panel-details-text
{
  position: absolute;
  font-size: x-small;
}
/***************************/
@supports ((position: -webkit-sticky) or (position: sticky))
{
  .sticky-top { 
    position: sticky!important;
    top: 0;
    z-index: 1020;
  }
}

.display-0
{
  font-size: 10rem;
    font-weight: 300;
    line-height: 1.2;
}

/******* Dropzone **********/ 
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
} 
/**************************/
.btn-purple {
  color: #fff!important;
  background-color: #8367AE!important;
  border-color: #8367AE!important;
}
/*************************/ 
.rdw-editor-wrapper ~ .invalid-feedback {
  display: block;
}

.is-invalid-form-field .rdw-editor-main
{
  border: 1px solid #f86c6b!important; 
}

/*************************/ 
.rbc-agenda-view table.rbc-agenda-table tbody td.rbc-agenda-time-cell {
  border-left: 1px solid #CDD!important; 
}
/*************************/ 
.texts-with-icon-box
{
  padding: 1em; 
}

.texts-with-icon-box  .icon
{
  display: flex;
    align-items: center;  /* Centers vertically */
    justify-content: center;  /* Centers horizontally */
}
.texts-with-icon-box i
{
  font-size: 6em;
  color: #4dbd74; 
} 

.texts-with-icon-box  .texts
{ 
  border-left: 1px solid #d2d2d2; 
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  z-index: 10000;
}

.border-none
{
  border: none!important;
}

.left-bordered
{
  border-left: 1px solid #d2d2d2;
}