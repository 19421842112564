// Variable overrides
$footer-bg: white;

$aside-menu-width: 360px;

$sidebar-width: 240px;

$theme-colors: (
  "attendee-app": #6FA335,
  "leads-app": #ee992e,
  "tracker-app": #0B7BC3,
  "tracker-web": #20a8d8,
  "conference": #F44336,
  "attendees": #009688,
  "badges": #E91E63,
  "attendance": #4CAF50,
  "leads": #FF9800,
  "social": #2035f1,
  "polls": #9C27B0,
  "surveys": #4CAF50,
  "newsletters": #90A4AE,
  "certificates": #FFC107,
  "transcripts": #03A9F4,
  "invoices": #536DFE,
  "analytics": #FF5722,
  "advanced-options": #8BC34A,
  "profile": #f17c2d,
  "registrations":  #20a8d8,
  "communications":  #9C27B0,
  "feedback": #E91E63,
  "about": #0B7BC3,
);
