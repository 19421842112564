.datePickerWidth,
.datePickerWidth > div.react-datepicker-wrapper,
.datePickerWidth > div > div.react-datepicker__input-container
.datePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
}
.react-datepicker__input-container  {
  width: inherit;
}
.react-datepicker__input-container input {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-size: 11px !important;
}

.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 0 !important;
}

.react-datepicker-popper {
  min-width: 360px;
}